<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 offset-lg-4">
					<div class="login__lang header__lang">
						<HeaderLang></HeaderLang>
					</div>

					<div class="header__logo login__logo">
						<div class="header__logo--left">
							<a :href="'/' + $i18n.locale + '/'">
								<img src="../assets/img/logo.svg" alt="" />
							</a>
						</div>
						<div class="header__logo--right">
							<div class="header__logo--title">
								{{ $t('site.logo.title') }}
							</div>
							<div class="header__logo--subtitle">
								{{ $t('site.logo.desc') }}
							</div>
						</div>
					</div>

					<div class="login__block" v-if="success == 'success'">
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<div class="recovery__block recovery__block--success">
											<div class="recovery__block--title">
												{{ $t('site.recovery.success.title') }}
											</div>
											<div class="recovery__block--link">
												<router-link :to="'/' + $i18n.locale + '/recovery'">{{
													$t('site.recovery.come_back')
												}}</router-link>
											</div>
										</div>
									</div>
								</div>

								<div class="col-lg-12">
									<div class="form__listitem--registr">
										<router-link :to="'/' + $i18n.locale + '/login'">{{
											$t('site.authorization.input_login')
										}}</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="login__block" v-else-if="success == 'error'">
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<div class="recovery__block recovery__block--error">
											<div class="recovery__block--title">
												<!--<strong>IvanIvanov</strong><br>-->
												{{ $t('site.recovery.user_not_found') }}
											</div>
											<div class="recovery__block--link">
												<router-link :to="'/' + $i18n.locale + '/recovery'">{{
													$t('site.recovery.come_back')
												}}</router-link>
											</div>
										</div>
										<div class="recovery__block--line">
											<label class="form__block--input">
												<div class="form__block--title">
													{{ $t('site.recovery.contact_administrator') }}
												</div>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="login__block" v-else>
						<div class="content__title--block">
							<div class="content__title section__title">
								{{ $t('site.recovery.title') }}
							</div>
						</div>
						<p class="text-sm mb-[10px]">{{ $t('messages.end_creating_account') }}</p>
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<form method="POST" @submit.prevent="onSubmit">
											<input type="hidden" name="token" :value="$route.params.token" />

											<div class="form__block--line">
												<label
													class="form__block--input"
													:class="[
														errorMessage.messages && errorMessage.messages.password
															? 'input--error'
															: '',
													]"
												>
													<input
														type="password"
														v-if="!inputType"
														class="input-linck input-linck-icon"
														v-model="password"
														required=""
														name="password"
														value=""
														:placeholder="$t('site.authorization.input.password')"
													/>
													<input
														type="text"
														v-else
														class="input-linck input-linck-icon"
														v-model="password"
														required=""
														name="password"
														value=""
														:placeholder="$t('site.authorization.input.password')"
													/>
													<div class="input__icon">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 11H5a2 2 0 00-2 2v7a2 2 0 002 2h14a2 2 0 002-2v-7a2 2 0 00-2-2zM7 11V7a5 5 0 1110 0v4" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</div>
													<div
														class="password__eye"
														v-tooltip.top-center="{
															content: $t('site.authorization.input.password_length'),
															class: ['tooltip__btn'],
														}"
													>
														<img
															src="../assets/img/icon-eye-off.svg"
															v-if="inputType"
															@click="inputType = false"
														/>
														<img
															src="../assets/img/icon-eye.svg"
															v-else
															@click="inputType = true"
														/>
													</div>
												</label>
												<p
													class="text-[12px] mt-[10px] whitespace-pre-line"
													v-html="$t('messages.password_msg')"
												></p>
												<div
													class="input-required"
													v-if="errorMessage.messages && errorMessage.messages.password"
												>
													<p
														v-for="(massage, massageInd) in errorMessage.messages.password"
														:key="massageInd"
													>
														{{ massage }}
													</p>
												</div>
											</div>

											<div class="form__block--line form__info--line">
												<label class="form__block--input">
													<span class="form__block--title">
														{{ $t('site.recovery.form.text') }}
													</span>
												</label>
											</div>

											<div
												class="form__block--line form__messages"
												v-if="message.text"
												:class="{
													'form__messages--error': message.status != 200,
													'form__messages--success': message.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ message.text }}
													</span>
												</label>
											</div>

											<div class="form__listitem--line form__listitem--button">
												<div class="form__listitem--input">
													<button class="input__button">
														{{ $t('site.recovery.send') }}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { api } from '@/boot/axios'
import HeaderLang from '@/components/HeaderLang'

export default {
	components: {
		HeaderLang,
	},
	data() {
		return {
			success: null,
			token: null,
			message: {
				status: null,
				text: null,
			},
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			loading: null,

			password: '',
			inputType: false,
		}
	},
	beforeCreate() {
		if (localStorage.token) {
			delete localStorage.token

			location.reload()
		}
	},
	methods: {
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			const formData = new FormData(evt.target)

			api
				.post('login/forgot/code', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = response.status
						this.message.text = response.data.message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
				})
				.catch((error) => {
					if (error?.response?.status == 401) {
						this.message.status = 401
						this.message.text = error?.response?.data?.message
					}
					if (error?.response?.status == 422) {
						this.message.status = 422
						// this.message.text = error?.response?.data?.message;
						this.errorMessage.status = 422
						if (error.response.data.error_message)
							this.errorMessage.text = error.response.data.error_message
						if (error.response.data.error_messages)
							this.errorMessage.messages = error.response.data.error_messages
						if (error.response.data.error_message)
							this.message.text = error.response.data.error_message
					}
					// this.message.status = error?.response?.status;
					// this.message.text = error?.response?.data?.message;
				})
		},
	},
	head: {
		title() {
			return {
				inner: this.$t('site.recovery.title'),
			}
		},
		meta: function () {
			return [{ name: 'description', content: this.$t('site.recovery.description') }]
		},
	},
}
</script>

<style>
header .header__site--row {
	display: none;
}
</style>
